const parseDatalakeValue = (value: string, columnType: string) => {
  if (columnType === "INT") {
    const parsed = parseInt(value);
    return isNaN(parsed) ? 0 : parsed;
  } else if (columnType === "DOUBLE" || columnType === "LONG") {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  }
  return value;
};

export default parseDatalakeValue;
