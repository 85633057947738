import getData from "@/__main__/get-data.mjs";
import fetchSeasons from "@/game-fortnite/fetches/seasons.mjs";
import { fetchWeapons } from "@/game-fortnite/fetches/static.mjs";
import RankStatsValidateFn from "@/game-fortnite/models/rank-statistics.mjs";
import WeaponStatsValidateFn from "@/game-fortnite/models/weapon-statistics.mjs";
import { writeWeaponStatsWithKey } from "@/game-fortnite/utils/actions.mjs";
import * as API from "@/game-fortnite/utils/api.mjs";
import {
  getParams,
  getWeaponsPageParamsStr,
  WEAPON_PAGE_DEFAULT_OPTIONS,
} from "@/game-fortnite/utils/get-params.mjs";

async function fetchData(_, searchParams: URLSearchParams) {
  await fetchWeapons();
  const seasons = await fetchSeasons();
  const defaultOptions = {
    ...WEAPON_PAGE_DEFAULT_OPTIONS,
    season: seasons[0]?.id,
  };
  const {
    selectedMode: mode,
    season,
    selectedRank: rank,
    selectedQueue: queue,
  } = getParams(searchParams, defaultOptions);
  const key = getWeaponsPageParamsStr(searchParams, defaultOptions);
  const promises = [];

  const getWeaponStats = getData(
    API.getWeaponStatistics({
      seasonId: season.toString(),
      mode: queue.dataKey,
      playlist: mode.dataKey,
      rank: rank?.backendKey || "ALL",
    }),
    WeaponStatsValidateFn,
    ["fortnite", "stats", "weapons", key],
  ).then((stats) => writeWeaponStatsWithKey(key, stats));

  promises.push(getWeaponStats);

  if (mode.isRanked) {
    const getRankStats = getData(
      API.getRankStatistics({
        seasonId: season.toString(),
        playlist: mode.dataKey,
        mode: queue.dataKey,
      }),
      RankStatsValidateFn,
      ["fortnite", "stats", "ranks", key],
    );

    promises.push(getRankStats);
  }

  return Promise.all(promises);
}

export default fetchData;
