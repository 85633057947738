import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel, { Mapped, Union } from "@/__main__/data-model.mjs";
import parseDatalakeValue from "@/util/parse-datalake-value.mjs";

const DATA_KEY_MAPPER = {
  weapon_id: "weaponId",
  avg_critical_damage_done_to_players_per_match: "criticalDamage",
  avg_damage_done_to_players_per_match: "damage",
  accuracy: "accuracy",
  elims_per_match: "elims",
  headshot_percentage: "headshotPercent",
  num_games: "matches",
};

const StatModel = {
  criticalDamage: Number,
  damage: Number,
  elims: Number,
  accuracy: Number,
  headshotPercent: Number,
  matches: Number,
} satisfies ModelDefinition;

export type FortniteWeaponStats = FromModel<typeof model>;

const model = Mapped({
  key: String,
  value: StatModel,
});

export type WeaponStatsMap = FromModel<typeof model>;

const columnModel = {
  name: String,
  position: Number,
  typeName: String,
  typeText: String,
} satisfies ModelDefinition;

const validateFn = createModel({
  data: {
    executeDatabricksQuery: {
      payload: {
        manifest: {
          schema: {
            columns: [columnModel],
          },
        },
        result: {
          dataArray: [[Union([String, null] as [StringConstructor, null])]],
        },
      },
    },
  },
}).transform(model, (data) => {
  const columns =
    data.data.executeDatabricksQuery.payload.manifest.schema.columns;
  const dataArray = data.data.executeDatabricksQuery.payload.result.dataArray;

  const transformedData: WeaponStatsMap = {};

  for (const item of dataArray) {
    let weaponId: string | number;
    const weaponData = {};
    for (const [index, value] of Object.entries(item)) {
      const column = columns[index];
      if (!(column.name in DATA_KEY_MAPPER)) continue;
      if (column.name === "weapon_id") {
        weaponId = parseDatalakeValue(value, column.typeName);
        continue;
      }
      weaponData[DATA_KEY_MAPPER[column.name]] = parseDatalakeValue(
        value,
        column.typeName,
      );
    }
    if (!weaponId) continue;
    // @ts-expect-error
    transformedData[weaponId.toString()] = weaponData;
  }

  return transformedData;
});

export default validateFn;
